<template>
    <b-overlay :show='showLoader'>
        <b-card class="open-items mb-0">
            <h1 class="text-secondary font-title">Odprte postavke</h1>
            <b-row class="m-0 mt-2 d-flex justify-content-end align-items-start">
                <b-col class="flex p-1" style="color: #7da5d7;font-weight: bolder; background-color: #ebf3f9; text-align: end">
                    <span>Skupni saldo(€) : {{getSumSaldo}}</span>
                </b-col>
            </b-row>
            <!--            <div v-if="data.length > 0">
                <h3 class="mt-2 font">Izpis od {{dayjs(from).format('DD.MM.YYYY')}} do {{dayjs(to).format('DD.MM.YYYY')}}</h3>
            </div>-->
            <my-portal-table class="mt-1" :data="data" :fields="fields">
                <template #cell()="row">
                    <template v-if="row.field.type === 1">
                        {{ dayjs(row.item[row.field.key]).format('DD.MM.YYYY') }}
                    </template>
                    <template v-else-if="row.field.type === 2">
                        {{ $convertFloat(row.item[row.field.key])}}
                    </template>
                    <template v-else-if="row.field.type === 3">
                        <span v-if="row.item[row.field.key] === '0'">ni toženo</span>
                        <span v-else>toženo</span>
                    </template>
                    <template v-else>
                        {{ row.item[row.field.key]}}
                    </template>
                </template>
                <!--                <template #custom-foot>
                    <template v-if="data.length > 0">
                        <th colspan="6">Skupaj</th>
                        <th>{{$convertFloat(data.reduce((a, b) => a + b.dobro, 0))}}</th>
                        <th>{{$convertFloat(data.reduce((a, b) => a + b.saldo, 0))}}</th>
                    </template>
                </template>-->
            </my-portal-table>
            <p class="pl-1" style="font-size: 10px !important;">Izpis je informativne narave, saj lahko prihaja do zamika prejetih plačil.</p>
        </b-card>
    </b-overlay>
</template>

<script>
    //import flatPickr from 'vue-flatpickr-component'
    import 'flatpickr/dist/flatpickr.css'
    import {Slovenian} from 'flatpickr/dist/l10n/sl.js'
    //import {BRow, BCol, BFormGroup, BInputGroup, BInputGroupAppend, BOverlay, BCard} from 'bootstrap-vue'
    import {BOverlay, BCard, BRow, BCol} from 'bootstrap-vue'
    //import RoundedButton from '@/views/Components/RoundedButton.vue'
    import MyPortalTable from '@/views/Components/MyPortalTable'
    export default {
        components: {
            MyPortalTable,
            BOverlay,
            BRow,
            BCol,
            BCard/*,
            flatPickr,
            BFormGroup,
            BInputGroup,
            BInputGroupAppend,
            RoundedButton*/
        },
        data() {
            return {
                showLoader: false,
                from: new Date(),
                to: new Date(),
                flatpickrConfig: { altInput: true, dateFormat: 'Z', altFormat: 'd. m. Y', wrap: true, locale: Slovenian},
                fields: [
                    { key: 'sklic', label: 'Sklic', sortable: false, class: 'text-left', type: 0 },
                    { key: 'datum_prenosa', label: 'Obračun', sortable: false, class: 'text-left', type: 1 },
                    { key: 'datum_dokumenta', label: 'Datum dok.', sortable: false, class: 'text-left', type: 1 },
                    { key: 'datum_valute', label: 'Datum valute', sortable: false, class: 'text-left', type: 1 },
                    //{ key: 'status_tozbe', label: 'Status tožbe', sortable: false, class: 'text-left', type: 3 },
                    { key: 'breme', label: 'Breme (€)', sortable: false, class: 'text-left', formatter: val => { return this.$convertFloat(val) }, type: 2 },
                    { key: 'dobro', label: 'Dobro (€)', sortable: false, class: 'text-left', formatter: val => { return this.$convertFloat(val) }, type: 2 },
                    { key: 'saldo', label: 'Saldo (€)', sortable: false, class: 'text-left', formatter: val => { return this.$convertFloat(val) }, type: 2 }
                ],
                data: []
            }
        },
        methods: {
            loadData() {
                const thisIns = this
                thisIns.showLoader = true

                /* const from = this.dayjs(this.from).format('YYYY-MM-DD')
                const to = this.dayjs(this.to).format('YYYY-MM-DD')*/

                thisIns.$http.get('/api/iiportal-user/v1/e_manager/odprta_postavka/')
                    .then(res => {
                        this.data = res.data
                    })
                    .catch(error => {
                        thisIns.$printError(`Pri nalaganju podatkov je prišlo do napake!\n${error.message}`)
                    }).finally(() => {
                        thisIns.showLoader = false
                    })
            }
        },
        computed:{
            getSumSaldo() {
                let sum = 0
                if (this.data.length > 0) {
                    this.data.forEach((x) => {
                        sum = sum + x.saldo
                    })
                }
                return sum
            }
        },
        mounted() {
            const now = new Date()
            this.from = new Date(now.getFullYear(), now.getMonth(), 1)
            this.to = new Date()

            this.loadData()
        }
    }
</script>

<style lang="scss" scoped>
.open-items{
    border-radius: 10px !important;
}
.open-items .font {
    font-weight: bold;
}
.open-items .font-title {
    font-weight: bold;
    color: #72a5d8 !important;
}
</style>

<style>
 .open-items .button-show{
     background: #72a5d8 !important;
    border: none;
    color: white;
 }
 .open-items .button-show:hover, .button-show:active, .button-show:focus{
     background: #72a5d8 !important;
    border: none;
    color: white;
    box-shadow: none !important;
 }
.open-items .append-button {
    background: #72a5d8 !important;
    border: none;
    color: white;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.input-group > .form-control {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
        transition: border-color 0.15s ease-in-out !important;
}
.open-items .open-items .button-show{
    background: #72a5d8 !important;
    border: none !important;
}

.open-items .button-show:hover, .button-show:active, .button-show:focus {
    box-shadow: none !important;
    background: #72a5d8 !important;
    border: none !important;
}
</style>